<!-- 首页 -->
<template>
  <div class="home-frame">
    <div class="top">
      <div class="left">
        <img src="../assets/logo.png" alt="" class="logo" />
        <div class="title">
          <div class="ch">投资黑水 共享机遇—黑水县招商地图</div>
          <div class="en">Investment in Heishui and share opportunities</div>
        </div>
      </div>
      <div class="right">
        <div class="nav active">
          <img src="../assets/home1_active.png" alt="" />
          <span>首页</span>
        </div>
        <div class="nav" @click="navTo('/move/traffic')">
          <img src="../assets/home2.png" alt="" class="icon" />
          <img src="../assets/home2_active.png" alt="" class="active_icon" />
          <span>走进黑水</span>
        </div>
        <div class="nav" @click="navTo('/chance')">
          <img src="../assets/home3.png" alt="" class="icon" />
          <img src="../assets/home3_active.png" alt="" class="active_icon" />
          <span>投资机会</span>
        </div>
        <div class="nav" @click="navTo('/route')">
          <img src="../assets/home4.png" alt="" class="icon" />
          <img src="../assets/home4_active.png" alt="" class="active_icon" />
          <span>投资考察路线</span>
        </div>
        <div class="nav" @click="navTo('/policy')">
          <img src="../assets/home5.png" alt="" class="icon" />
          <img src="../assets/home5_active.png" alt="" class="active_icon" />
          <span>投资政策</span>
        </div>
      </div>
    </div>
    <div class="center">
      <img src="../assets/slogan.png" alt="" class="slogan" />
      <div class="banner">
        <el-carousel :interval="8000" arrow="hover">
          <el-carousel-item v-for="item in bannerList" :key="item.id">
            <div class="img">
              <img :src="URL + item.sourceUrl" alt="" />
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="data-box">
        <div class="data qiye">
          <div class="label">
            <div class="title">企业</div>
            <div class="more" @click="navTo('/move/enterprise')">more</div>
          </div>
          <div class="list">
            <div
              class="item"
              v-for="item in enterpriseList"
              :key="item.id"
              @click="getEnterDetail(item)"
            >
              <div class="left">
                <span>{{ item.enterpriseName }}</span>
              </div>
              <div class="right">
                <span>{{ item.industryName }}</span>
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="data tzjh">
          <div class="label">
            <div class="title">投资机会</div>
            <div class="more" @click="navTo('/chance')">more</div>
          </div>
          <div class="list">
            <div
              class="item"
              v-for="item in investList"
              :key="item.id"
              @click="getInvestDetail(item)"
            >
              <div class="left">{{ item.projectName }}</div>
            </div>
          </div>
        </div>
        <div class="data tzzc">
          <div class="label">
            <div class="title">投资政策</div>
            <div class="more" @click="navTo('/policy')">more</div>
          </div>
          <div class="list">
            <div
              class="item"
              v-for="item in policyList"
              :key="item.id"
              @click="getPolicyDetail(item)"
            >
              <div class="left">{{ item.policyName }}</div>
              <div class="right" style="font-size: 12px; color: #666">
                <span>{{
                  handleTime(item.publishTime || item.createdTime)
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Bottom></Bottom>
    <div class="body" style="display: none">
      <div class="content">
        <img src="../assets/slogan.png" alt="" class="slogan" />
        <div class="title">投资黑水 共享机遇—黑水县招商地图</div>
        <div class="en">Investment in Heishui and share opportunities</div>
        <div class="contact">
          <div class="item">
            <img src="../assets/home_phone.png" alt="" />
            <div class="text">招商热线</div>
            <div class="val">400-000-000</div>
          </div>
          <div class="item">
            <img src="../assets/home_email.png" alt="" />
            <div class="text">联系邮箱</div>
            <div class="val">heishuizhaoshang@163.com</div>
          </div>
          <div class="item">
            <img src="../assets/home_iner.png" alt="" />
            <div class="text">黑水县人民政府</div>
            <div class="val">www.heishui.gov.cn</div>
          </div>
        </div>
        <div class="bottom">
          <div class="m">
            <img src="../assets/home_code.png" alt="" />
            <div class="text">手机版</div>
          </div>
          <div class="navs">
            <router-link to="/move/traffic">
              <div class="nav nav1">
                <img class="icon" src="../assets/nav1.png" alt="" />
                <img class="active" src="../assets/nav1_active.png" alt="" />
                <div class="text">走进黑水</div>
              </div>
            </router-link>
            <router-link to="/chance">
              <div class="nav nav2">
                <img class="icon" src="../assets/nav2.png" alt="" />
                <img class="active" src="../assets/nav2_active.png" alt="" />
                <div class="text">投资机会</div>
              </div>
            </router-link>
            <router-link to="/route">
              <div class="nav nav3">
                <img class="icon" src="../assets/nav3.png" alt="" />
                <img class="active" src="../assets/nav3_active.png" alt="" />
                <div class="text">投资考察路线</div>
              </div>
            </router-link>
            <router-link to="/policy">
              <div class="nav nav4">
                <img class="icon" src="../assets/nav4.png" alt="" />
                <img class="active" src="../assets/nav4_active.png" alt="" />
                <div class="text">投资政策</div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div class="icp">
        {{ $store.globalSet.copyRightMsg + $store.globalSet.icpMsg }}
      </div>
    </div>
  </div>
</template>

<script>
import Bottom from "../components/bottom.vue";
import { URL } from "../util/config";
export default {
  data() {
    return {
      URL,
      carouselHeight: "",
      bannerList: [],
      enterpriseList: [],
      investList: [],
      policyList: [],
    };
  },
  components: { Bottom },
  computed: {
    handleTime() {
      return (time) => {
        return time && time.split(" ")[0];
      };
    },
  },
  created() {
    this.carouselHeight = window.innerHeight - 170 + "px";
    this.getBanner();
    this.getEnterprise();
    this.getChance();
    this.getPolicy();
  },
  mounted() {},
  methods: {
    async getBanner() {
      let res = await this.$api.getBanner();
      this.bannerList = res.data;
    },
    async getEnterprise() {
      const params = {
        pageIndex: 1,
        pageSize: 4,
      };
      let res = await this.$api.getEnterprise(params);
      this.enterpriseList = res.data.list;
    },
    async getChance() {
      const params = {
        pageIndex: 1,
        pageSize: 4,
      };
      let res = await this.$api.getInvestList(params);
      this.investList = res.data.list;
    },
    async getPolicy() {
      const params = {
        pageIndex: 1,
        pageSize: 4,
      };
      let res = await this.$api.getPolicy(params);
      this.policyList = res.data.list;
    },
    navTo(path) {
      this.$router.push(path);
    },
    getEnterDetail(item) {
      this.$router.push("/move/enterprise?name=" + item.enterpriseName);
    },
    getInvestDetail(item) {
      this.$router.push("/chance?name=" + item.projectName);
    },
    getPolicyDetail(item) {
      if (item.policyModule == 1) {
        // 外链直接跳转
        window.open(item.policyContext);
      } else {
        this.$router.push("/policy?id=" + item.id);
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.home-frame {
  background-color: #fff;
}
::v-deep {
  .el-carousel__container {
    height: calc(100vh - 170px);
  }
  .el-carousel__indicators--horizontal {
    bottom: 2%;
    left: 50%;
    transform: translateX(-50%);
    transform: scale(1);
  }
}
.top {
  min-width: 1200px;
  box-sizing: border-box;
  height: 80px;
  padding: 0 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left {
    display: flex;
    align-items: center;

    .logo {
      width: 50px;
      height: 50px;
      margin-right: 10px;
    }

    .title {
      font-size: 25px;
      .ch {
        font-weight: bold;
      }
      .en {
        font-size: 14px;
        color: #999;
      }
    }
  }
  .right {
    display: flex;

    .nav {
      height: 60px;
      margin-left: 40px;
      font-weight: bold;
      display: flex;
      align-items: center;
      cursor: pointer;

      &:hover {
        color: $theme;
        position: relative;

        .icon {
          display: none;
        }
        .active_icon {
          display: block;
        }

        &::after {
          content: "";
          width: 100%;
          height: 3px;
          border-radius: 6px;
          position: absolute;
          bottom: 0;
          left: 0;
          background-color: $theme;
        }
      }

      img {
        width: 20px;
        margin-right: 10px;
      }
      .active_icon {
        display: none;
      }
    }
    .active {
      color: $theme;
      position: relative;

      &::after {
        content: "";
        width: 100%;
        height: 3px;
        border-radius: 6px;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: $theme;
      }
    }
  }
}
.center {
  width: 100%;
  height: calc(100vh - 170px);
  position: relative;

  .slogan {
    width: 500px;
    position: absolute;
    left: 10%;
    top: 30%;
    z-index: 10;
  }
  .banner {
    width: 100%;
    height: 100%;

    .img {
      width: 100%;
      height: 100%;
      position: relative;

      img {
        width: 100%;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .data-box {
    width: 80%;
    background-color: rgba(250, 251, 253, 0.5);
    padding: 15px;
    box-sizing: border-box;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 50%;
    bottom: 10%;
    transform: translateX(-50%);
    z-index: 10;

    .data {
      width: 29%;
      border-radius: 10px;
      padding: 15px 20px;
      background-size: 100% 100%;

      .label {
        border-bottom: 1px solid #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 15px;

        .title {
          font-size: 20px;
          font-weight: bold;
          color: #fff;
        }
        .more {
          font-size: 12px;
          padding: 3px 10px;
          border-radius: 15px;
          color: $theme;
          cursor: pointer;
          background-color: #fff;
          transition: transform 0.5s;

          &:hover {
            transform: scale(1.1);
          }
        }
      }
      .list {
        margin-top: 20px;

        .item {
          width: 100%;
          margin-bottom: 16px;
          display: flex;
          justify-content: space-between;
          font-size: 15px;
          transition: transform 0.5s;
          padding-left: 10px;
          box-sizing: border-box;
          position: relative;

          &::after {
            content: "";
            display: block;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: $theme;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          }

          &:hover {
            color: $theme;
            cursor: pointer;
            transform: translateX(-6px);
          }
          .left {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .right {
            font-size: 14px;
            flex-shrink: 0;
            margin-left: 10px;

            span {
              margin-right: 6px;
            }
            i {
              color: #999;
            }
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    .qiye {
      background-image: url(../assets/move/move_bg.jpg);

      .list .item::after {
        width: 0;
        height: 0;
        border-width: 4px 5px;
        border-color: transparent transparent transparent $theme;
        border-style: solid;
        background-color: transparent;
        border-radius: 0;
      }
    }
    .tzjh {
      background-image: url(../assets/chance_bg.png);
    }
    .tzzc {
      background-image: url(../assets/policy_bg.png);

      .list .item::after {
        background-color: #999;
      }
    }
  }
}
.icp {
  font-size: 10px;
  margin-top: 10px;
  color: #666;
  text-align: center;
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
}
@media (max-width: 1500px) {
  ::v-deep .el-carousel__container {
    height: calc(100vh - 150px);
  }
  .top {
    height: 60px;

    .left {
      .title {
        font-size: 20px;

        .en {
          font-size: 12px;
        }
      }
      .logo {
        width: 40px;
        height: 40px;
      }
    }
    .right .nav {
      font-size: 14px;

      img {
        width: 15px;
      }
    }
  }
  .center {
    height: calc(100vh - 150px);

    .slogan {
      width: 400px;
    }
    .data-box .data {
      padding: 10px 15px;

      .label {
        .title {
          font-size: 16px;
        }
        .more {
          transform: scale(0.9);

          &:hover {
            transform: scale(1);
          }
        }
      }

      .list .item {
        font-size: 14px;
        margin-bottom: 10px;

        .right {
          font-size: 12px;
        }
      }
    }
  }
}
</style>
